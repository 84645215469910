// for npm roboto-fontface package (to load local files)
@import "~ngx-toastr/toastr";

$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "~roboto-fontface/css/roboto/sass/roboto-fontface";

// for npm material-icons package (to load local files)
$material-icons-font-path: "~material-icons/iconfont/";
@import "~material-icons/iconfont/material-icons.scss";

@import url("https://cdn.quilljs.com/1.2.2/quill.snow.css");
@import url("https://cdn.quilljs.com/1.2.2/quill.bubble.css");

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "app/theme/styles/base";
@import "app/theme/styles/theme";

.component_name {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.component_title {
  font-size: 20px;
}

h3,
h4,
p {
  font-family: Roboto, Arial, sans-serif;
}

.text-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.centered {
  font-family: sans-serif;
  font-size: 1.3em;
  text-align: center;
}

.dropzone {
  height: 200px;
  display: table;
  width: 100%;
  background-color: #eee;
  border: dotted 1px #aaa;
}

input[type="file"] {
  display: none;
}

.textLink {
  background-color: #f45e61;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.text-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.centered {
  font-family: sans-serif;
  font-size: 1.3em;
  text-align: center;
}

.fileItem {
  width: calc(25% - 6px);
  overflow: hidden;
  height: fit-content;
  margin: 3px;
  padding: 10px;
  display: block;
  position: relative;
  float: left;
  border: 2px dashed #778899;
  border-radius: 5px;
  transition: 0.3s ease;
}
.fileItem:hover {
  border: 2px solid #c33838;
  background-color: #c33838bf;
}
.fileItem:hover .fileItemIcon::before {
  content: "\f00d";
  color: whitesmoke;
}
.fileItem:hover .fileItemText {
  color: whitesmoke;
}
.fileItemIconDiv {
  text-align: center;
}
.fileItemIcon::before {
  font-style: normal;
  content: "\f15b";
  color: #778899;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.fileItemText {
  text-align: center;
  margin-top: 10px;
  height: 40px;
}

.mat_header {
  text-align: center !important;
}

.no-data-message {
  text-align: center;
  font-size: 24px;
  margin-top: 40px;
}

.custom-logo {
  filter: brightness(10);
  height: 80;
  width: 250;
}

.Complete > div {
  color: green;
  // font-weight: bold;
}
.Processing > div {
  color: rgb(255, 168, 6);
  // font-weight: bold;
}

.Fail > div {
  color: rgb(255, 2, 2);
  // font-weight: bold;
}
.RollBack > div {
  color: green;
}

.mb-5 {
  margin-bottom: 5px;
}
.mr-10 {
  margin-right: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.font-size-14 {
  font-size: 14px;
}
